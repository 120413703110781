import {DOMAINS} from '@content/domains';

export const content = {
  pageTitles: {
    DTG: 'Distributions And Tax Gains | Vanguard',
  },
  dtgLayout: {
    expandAll: 'Expand all accounts',
    collapseAll: 'Collapse all accounts',
    resetSorting: 'Reset sorting',
    definitions: 'Definitions',
    changeDistribution: 'Change distribution elections',
    changeDistributionLink: `${DOMAINS.PERSONAL1_B}/dnd-div-cap-gains/landing`,
    accountGroupingHeaders: {
      summaryOfAccounts: 'Summary of accounts ',
      managedHeader: 'Advised accounts total: ',
      selfManagedHeader: 'Self-managed accounts total: ',
    },
  },
  dtgToolbar: {
    show: 'Show:',
    selectQuarter: 'Quarter: ',
    dataUnavailableText:
      'Our system is temporarily unable to display some of your account information. We apologize for the inconvenience and appreciate your patience while we fix these issues. Please refresh in a few minutes or choose another page.',
    accountsUnavailableText:
      "Our mutual fund-only investment platform will be retiring soon. To continue viewing all your account details, you'll need to transition to a ",
    bannerText1:
      'Historical data is unavailable for mutual fund-only accounts. Data related to closed accounts or closed holdings in an account are temporarily unavailable on this page. To view these details, go to your ',
    bannerText2: 'dividend and capital gains distributions legacy page. ',
    bannerText3: 'We’re currently working to resolve these issues. We apologize for the inconvenience.',
    bannerText4: 'Historical data is unavailable for mutual fund-only accounts. We’re currently working to resolve these issues. We apologize for the inconvenience.',
    bannerLink: `${DOMAINS.PERSONAL}/us/myaccounts/divcapgains`,
    recentDistTitle: 'Recent distributions',
    recentDistText1: 'The tables below list the most recently posted distributions. Not all mutual funds distribute dividends on a quarterly basis. Some dividends are issued monthly, semiannually, or annually. ',
    recentDistText2: 'View distributions',
    recentDistText3: ' or change the date range to see additional distribution payouts.',
  },
  pageSelectorContent: [
    {
      title: '',
      items: [
        {name: 'Summary information', url: `${DOMAINS.HOLDINGS}/`, routerLink: ''},
        {
          name: 'Balances by date',
          url: `${DOMAINS.BYD}`,
          routerLink: '',
        },
        {
          name: 'Balances compared to year-end',
          url: `${DOMAINS.BYE}`,
          routerLink: '',
        },
        {name: 'Prices & returns', url: `${DOMAINS.HOLDINGS}/prices-and-returns`, routerLink: ''},
      ]
    },
    {
      title: 'Cost basis',
      items:     [
        {
          name: 'Unrealized gains/losses',
          url: `${DOMAINS.COST_BASIS}/unrealized`,
          routerLink: '',
        },
        {
          name: 'Realized gains/losses',
          url: `${DOMAINS.COST_BASIS}/realized`,
          routerLink: '',
        },
        {name: 'Gifted shares', url: `${DOMAINS.COST_BASIS}/gifted`, routerLink: ''},
      ]
    },
    {
      title: 'Dividends & capital gains',
      items: [
        {
          name: 'Taxable gains',
          url: `${DOMAINS.DTG}/taxable-gains`,
          routerLink: 'taxable-gains',
        },
        {
          name: 'Nontaxable gains',
          url: `${DOMAINS.DTG}/nontaxable-gains`,
          routerLink: 'nontaxable-gains',
        },
        {
          name: 'Distribution payouts',
          url: `${DOMAINS.PERSONAL}/us/myaccounts/divcapgains`,
          routerLink: '',
        },
      ]
    }
  ],
  dtgStaticContent: {
    content1:
      '*Brokerage assets are held by Vanguard Brokerage Services, a division of Vanguard Marketing Corporation, member',
    content2: ' and',
    content3: 'Note that data presented here are for informational purposes only.',
    content4:
      'Refer to your tax forms for final capital gains and dividend information. Dividend and capital gains distributions presented here may differ from the distributions reported to you on Form 1099-DIV. For example, under federal tax law, dividends declared by regulated investment companies and real estate investment trusts in October, November, and December but paid the following January are taxable in the year declared, rather than the year paid. Additionally, the portion of a distribution which is a return of capital is generally not known until after the close of the tax year.',
    content5: 'Original issue discount (OID) income is not included.',
    content6:
      'If you need assistance estimating your income for tax purposes, please consult your tax advisor.',
    content7: 'Related Links',
    content9:'**Values shown include your mutual fund account before your mutual fund assets were transferred into an upgraded brokerage account.',
    FINRA: {
      url: 'https://www.finra.org/investors#/',
      label: 'FINRA',
    },
    SIPC: {
      url: 'https://www.sipc.org/',
      label: 'SIPC',
    },
    content10: ".",
    noTableItems: {
      'taxable-gains': 'You have no taxable investment income for the time period selected.',
      'nontaxable-gains': 'You have no nontaxable investment income for the time period selected.',
      'distribution-payouts': 'You have no distribution payouts investment income for the time period selected.'
    },
    relatedLinks: {
      header: 'Related links',
      quarterlyTaxSummaryLink: {
        label: 'Quarterly tax summary',
        url: `${DOMAINS.TAX_CENTER}/ytd-activity`,
      },
    },
  },
  distributionDefinition: [
    {
      title: 'Reinvest/Ex-div date:',
      text: [
        'The date on which an investment\'s dividend or capital gains distribution is reinvested, if requested by the shareholder, to purchase additional shares.',
      ],
    },
    {
      title: 'Payable date:',
      text: [
        'The date when dividends or capital gains are paid to shareholders. For Vanguard mutual funds, the payable date is usually within two to four days of the record date. The payable date also refers to the date on which a declared stock dividend or bond interest payment is scheduled to be paid.',
      ],
    },
  ],

  dtgDefinitions: [
    {
      title: 'Dividends',
      text: [
        'Distributions may be in the form of cash, stock, or other property and may include return of capital.',
      ],
    },
    {
      title: 'Interest',
      text: [
        'Distributions, usually in the form of cash, from bonds, notes, and other debt instruments.',
      ],
    },
    {
      title: 'Short-term capital gains',
      text: [
        'Distributions paid by mutual funds or REITs from their net realized capital gains.',
        'Short-term capital gains distributions are included as ordinary dividends in Box 1a of Form 1099-DIV.',
      ],
    },
    {
      title: 'Long-term capital gains',
      text: [
        'Distributions paid by mutual funds or REITs from their net realized capital gains.',
        'Long-term capital gains distributions are included in Box 2a of Form 1099-DIV, reported on schedule D of your Form 1040 and taxed at the long-term capital gain rate.',
      ],
    },
    {
      title: 'Other income',
      text: [
        'Other income may include return of capital, partnership distributions, royalty payments, liquidations, or principal payments.',
      ],
    },
    {
      title: 'Total distributions',
      text: [
        "Includes dividends, return of capital, interest, short-term and long-term capital gains distributions. Amounts may be based on estimates and shouldn't be used for tax reporting purposes.",
      ],
    },
  ],
  settlementFund: {
    title: 'Settlement fund',
    labelText: '(Settlement fund)',
  },

  adobeTrackingContent:{
    vbaLinkTaxable:  "Vanguard Brokerage Account_taxable gains",
    makeTheChangeTaxable:"Make the change now_taxable gains",
    vbaLinkNonTaxable: "Vanguard Brokerage Account_non-taxable gains",
    makeTheChangeNonTaxable: "Make the change now_non-taxable gains",
    location: "secure site holdings banner",
    vbaBannerCloseBtnTaxable: "close banner_taxable gains",
    vbaBannerCloseBtnNonTaxable: "close banner_non-taxable gains"
  }
};
