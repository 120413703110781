export const DOMAINS = {
  PERSONAL: 'https://personal.vanguard.com',
  PERSONAL1: 'https://personal1.vanguard.com',
  PERSONAL1_B: 'https://personal1.vanguard.com',
  COST_BASIS: 'https://cost-basis.web.vanguard.com',
  RETIREMENT_PLANS: 'https://retirementplans.vanguard.com',
  APPS: 'https://apps.vanguard.com',
  HOLDINGS: 'https://holdings.web.vanguard.com',
  BYD:'https://balance-by-date.web.vanguard.com/by-date',
  BYE:'https://balance-by-date.web.vanguard.com/compared-to-year-end',
  DTG:'https://distributions.web.vanguard.com',
  TAX_CENTER: 'https://taxcenter.cdn.web.vanguard.com'
};
