import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {NavigationEnd, Router, RouterEvent, RouterModule} from '@angular/router';
import {
  CustomizeViewModule,
  LoggerService,
  PageLayoutModule,
  SecondaryNavigationModule
} from "@vanguard/secure-site-components-lib";
import {LoggerCode} from "@models/logger";
import {filter, Subscription} from "rxjs";
import {map} from "rxjs/operators";
import {CommonModule, NgIf} from "@angular/common";
import {Title} from '@angular/platform-browser';
import {HttpClientModule} from "@angular/common/http";
import {content} from "@content/content";
import {ModalDialogEntrypointComponent} from "@vg-constellation/angular-15/modal-dialog";

@Component({
  standalone: true,
  selector: 'dtg-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    PageLayoutModule,
    SecondaryNavigationModule,
    NgIf,
    ModalDialogEntrypointComponent,
    CustomizeViewModule,
  ],
})
export class AppComponent implements OnInit{

  public displayCustomizeViewLink: boolean;

  private subscription: Subscription
  constructor(
    private router: Router,
    private title: Title,
    private logger: LoggerService,
  ) {
  }

  public ngOnInit(): void {
    this.pageLoadProcessing();
  }

  private pageLoadProcessing(): void {
    this.router.events.pipe(
      filter(
        (routerEvent: RouterEvent): routerEvent is NavigationEnd => routerEvent instanceof NavigationEnd
      ),
      map((navigationEndEvent: NavigationEnd) => {
        this.logEvent(navigationEndEvent.url);
        this.setTitle(navigationEndEvent.urlAfterRedirects);
      })
    ).subscribe()
  }

  setTitle(url: string): void {
    this.title.setTitle(content.pageTitles.DTG);
  }

  private logEvent(url: string): void {
    this.logger.info({
      eventType: 'PageLoadSuccess',
      logCode: LoggerCode.PAGE_LOAD,
      message: url
    });
  }

}
